import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"

const Gdpr = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Contract Furnizare Servicii | Termeni și condiții"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Contract Furnizare Servicii | Termeni și condiții"
      />
      <section className="privacy-policy-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="privacy-policy-content">
                <p>1.CADRU GENERAL</p>
                <p>
                  Acest document conţine termenii si conditiile de utilizare a
                  serviciilor oferite de EXIM HOST SRL prin intermediul
                  SiteBunker (<a href="www.SiteBunker.ro">www.SiteBunker.ro</a>)
                  sau alte site-uri detinute.
                </p>
                <p>2.DEFINITII</p>
                <p>
                  PRESTATORUL serviciilor de gazduire si inregistrare domenii
                  care pot fi contractate si cumparate prin intermediul
                  SiteBunker (<a href="www.SiteBunker.ro">www.SiteBunker.ro</a>)
                  si a celorlalte site-uri detinute este EXIM HOST SRL Cod
                  Inregistrare Fiscala: RO40672655; Str. Frunzei nr. 109,
                  Galați, România.
                </p>
                <p>
                  Utilizator – defineste orice persoana fizica sau juridica ce
                  foloseste serviciile SiteBunker.
                  <br />
                  Client – defineste orice persoana fizica sau juridica ce
                  initiaza o comanda.
                  <br />
                  Comanda – cerere adresata SiteBunker de catre o persoana
                  fizica sau juridica prin care se solicita servicii de gazduire
                  web sau inregistrare domenii si care nu creeaza obligatii
                  contractuale pentru parti.
                  <br />
                  3.INTRODUCERE
                </p>
                <p>
                  Art. 3.1. Prezentul document defineste cadrul in care
                  SiteBunker este de acord sa furnizeze produse si servicii
                  clientilor si utilizatorilor sai. Acest document reprezinta un
                  acord intre SiteBunker si clientii sai. Toti clientii si
                  utilizatorii serviciilor de hosting fac subiectul acestor
                  termeni si conditii astfel incat utilizarea serviciilor
                  SiteBunker presupune acceptarea si conformarea la Contractul
                  de Furnizare si laTermenii si Conditiile de Utilizare a
                  serviciilor ofertite de SiteBunker.
                </p>
                <p>Art. 3.2.</p>
                <p>
                  Prin completarea formularului de comanda si bifarea casutei:
                  Am citit si accept Contractul de Furnizare Servicii EXIM HOST
                  SRL:
                </p>
                <p>
                  – reprezinta o garantie ca sunteți persoana fizica, ca aveti
                  implinita varsta de 18 ani. In cazul in care reprezentati o
                  persoana juridica, parteneriat sau orice alta forma juridica
                  de organizare in conformitate cu standardele de comert
                  inregistrarea Dvs. este o garantie.
                </p>
                <p>
                  – sunteti de acord sa furnizati in Formularul de Comanda
                  informatii corecte, clare si reale, incluzand informatii
                  referitoare la contul de banca si orice alt tip de informatie
                  necesara pentru conditiile de plata si va angajati sa
                  actualizati in mod corect si permanent aceste informatii.
                </p>
                <p>
                  – reprezinta si garanteaza ca aveti autoritatea de a utiliza
                  serviciile conform prezentului contract. In cazul in care nu
                  sinteți de acord/nu va manifestati acordul asupra Termenilor
                  Contractului sau oricaror altor modificari, singura optiune
                  este de a va dezactiva de la prezentul serviciu.
                </p>
                <p>4.CONDITII GENERALE DE UTILIZARE</p>
                <p>
                  Art. 4.1. Utilizarea site-ului este posibila numai in situatia
                  acceptarii termenilor si conditiilor stabilite de catre
                  administratorii site-ului conform prevederilor legale.
                  Continuarea utilizarii site-ului reprezinta acceptul expres si
                  neechivoc pentru acceptarea tuturor termenilor si conditiilor
                  prezentati in Contractul de Furnizare si pe site-ul
                  SiteBunker.ro.
                </p>
                <p>
                  Art. 4.2. Folosirea,incluzand vizitarea si cumpararea
                  produselor si serviciilor de pe site-ul SiteBunker,implica
                  acceptarea si respectarea termenilor si conditiilor atat in
                  privinta utilizarii site-ului cat si in privinta achizionarii
                  de produse si servicii comercializate de SiteBunker.
                </p>
                <p>
                  Art. 4.3. In calitate de client sunteti de acord sa platiti
                  pentru toate produsele pe care le achizitionati prin
                  intermediul site-ului, si efectuati plata produselor comandate
                  de Dvs.
                </p>
                <p>
                  Art. 4.4. Clientul este responsabil pentru plata la timp a
                  tuturor comisioanelor si pentru a pune la dispozitia
                  SiteBunker sumele de bani, respectiv contravaloarea produselor
                  comandate de catre client.
                </p>
                <p>
                  Art. 4.5. Pretul total va include pretul produsului plus orice
                  TVA aplicabil (in vigoare la data comenzii si platii).
                </p>
                <p>
                  Art. 4.6. Clientul are dreptul de a se retrage din tranzactie
                  fara niciun cost si fara a da nici o justificare pana la data
                  procesarii comenzii. Clientul nu are dreptul de a se retrage
                  dintr-o tranzactie dupa momentul in care a inceput procesarea
                  comenzii, moment in care tranzactia clientului este definitiva
                  si confirmata de SiteBunker.
                </p>
                <p>
                  Art. 4.7. Pana la realizarea unei comenzi SiteBunker isi
                  rezerva dreptul de a modifica preturile pentru produsele
                  oferite prin intermediul site-ului, in orice moment, iar
                  SiteBunker nu ofera garantii de pret sau rambursari in
                  situatia unei reduceri a pretului sau a unei oferte
                  promotionale ulterior achizitiei.
                </p>
                <p>
                  Art. 4.8. Prin acceptarea termenilor si conditiilor prezentate
                  pe site-ul SiteBunker.ro sau site-urile afiliate, in momentul
                  plasarii unei comenzi, clientul accepta si isi da acordul
                  pentru incheierea unui contract la distanta in temeiul caruia
                  se va face vanzarea la distanta. Acest contract este unul de
                  adeziune si nu este supus modificarii sau negoicerii intre
                  parti.
                </p>
                <p>Art. 4.9. Programul de Afiliere</p>
                <p>
                  Prin programul de afiliere castigati 15% din valoarea
                  pachetelor de Gazduire de tip shared. Serviciul de
                  inregistrare domenii nu intra in programul de afiliere.
                </p>
                <p>Cum Functioneaza?</p>
                <p>
                  Fiecare afiliat va primi un link unic de referinta pe care il
                  va folosi clientii adusi de catre acesta. In momentul in care
                  un vizitator de pe site-ul dumneavoastra se conecteaza la
                  site-ul nostru prin acest link unic de referinta, sistemul
                  nostru va inregistra automat serviciile comandate de client.
                  Dupa efectuarea platii serviciilor, dumneavoastra o sa puteti
                  vedea in contul de afiliat comisionul de 15% din valoarea
                  serviciilor comandate.
                </p>
                <p>
                  Comisionarea programului de afiliere se va lua in considerare
                  doar daca afiliatul va aduce clienti unici per comision.
                  Comisionarea în cadrul programului de afiliere este strans
                  legată de aducerea de clienți unici. Acest lucru înseamnă că
                  afiliatul primește un comision doar dacă reușește să aducă un
                  client nou, care nu a mai folosit serviciile sau produsele
                  respective anterior.
                </p>
                <p>
                  Aspecte comune legate de comisionarea pe baza clienților
                  unici:
                </p>
                <p>
                  - Client unic: Este definit ca un client nou, care nu a mai
                  fost înregistrat în baza de date a companiei și care nu a mai
                  făcut achiziții anterior.
                </p>
                <p>
                  - Tracking: Se folosesc diverse metode de tracking pentru a
                  asigura că fiecare client adus este unic. Acestea includ
                  cookies, ID-uri de utilizator, și alte tehnologii de urmărire.
                </p>
                <p>
                  - Validare: După ce un client nou este înregistrat, programul
                  de afiliere verifică și validează dacă acesta este cu adevărat
                  unic, înainte de a acorda comisionul afiliatului.
                </p>
                <p>
                  - Plata comisionului: Afiliatul primește comisionul numai după
                  validarea clientului unic și, în unele cazuri, după ce
                  clientul nou efectuează o anumită acțiune (ex: cumpărare,
                  abonare la un serviciu).
                </p>

                <p>
                  Plata comisioanelor se va face dupa un termen de 30 de zile
                  daca se ajunge la o suma de minim 50 RON la cererea dvs. In
                  cazul platiilor online, termenul de plata al comisioanelor
                  poate fi amant pana la 60 de zile, pentru a evita fraudele.
                </p>
                <p>
                  5.ACCEPTAREA CONDITIILOR DE UTILIZARE. MODIFICAREA PERIODICA A
                  ACESTORA
                </p>
                <p>
                  Art. 5.1. Site-ul SiteBunker, serviciile si continutul
                  acestuia va sunt oferite conditionat de acceptarea
                  clientului/utilizatorului fara nici o modificare a conditiilor
                  din prezentul document. Daca in calitate de client nu
                  acceptati termenii si conditiile statuate in acest document,nu
                  folositi site-ul SiteBunker si serviciile oferite prin
                  intermediul acestuia.
                </p>
                <p>
                  Art. 5.2. Prin utilizarea site-ului, clientului indica si
                  declara ca acceptata fara nici o rezerva sau limitare sa
                  respectate “Termenii si Conditiile” de utilizare.
                  Administratorii site-ului, pot revizui acest document in orice
                  moment, prin actualizarea acestei pagini, sens in care, va
                  recomandam sa vizitati aceasta pagina periodic pentru a citi
                  “Termenii si Conditiile”, deoarece acest document obliga
                  clientul legal.
                </p>
                <p>
                  Art. 5.3. Fiecare accesare a site-ului reprezinta o noua
                  acceptare a termenilor si conditiilor in vigoare la acel
                  moment,asa cum acestea sunt afisate de catre Administratorul
                  site-ului. O acceptare a termenilor si conditiilor intr-o
                  forma precedenta, printr-o accesare anteriora a site-ului nu
                  afecteaza noile accesari supuse noilor tremeni si conditii.
                </p>
                <p>
                  Art. 5.4. Dovada continutului actual al termenilor si
                  conditiilor se va face, oriunde si oricand este nevoie,
                  printr-un document unilateral emanand de la Administratorul
                  Site-ului.
                </p>
                <p>6.OBLIGATIA DE INREGISTRARE PE SITE</p>
                <p>
                  Pentru a folosi serviciile oferite de site-ul SiteBunker,
                  utilizatorul se obliga :
                </p>
                <p>
                  Sa furnizeze datele de identificare adevarate, exacte si
                  complete asa cum este cerut de formularul de inregistrare al
                  site-ului;
                  <br />
                  Sa mentina si sa actualizeze, atunci cand situatia o cere,
                  datele de inregistrare pentru fi adevarate, exacte si
                  complete. Daca clientul furnizeaza informatii care nu sunt
                  conforme cu realitatea, inexacte sau incomplete,
                  administratorii site-ului SiteBunker au dreptul sa suspende
                  unilateral sau sa desfiinteze contul utilizatorului si sa
                  refuze toate incercarile curente sau viitoare de folosire a
                  site-ului SiteBunker.
                  <br />
                  7.CONTUL, PAROLA SI SECURITATEA DATELOR
                </p>
                <p>
                  Art. 7.1. Utlizatorul site-ului SiteBunker este raspunzator de
                  pastrarea confidentialitatii asupra informatiilor si a a
                  parolei personale, acesta fiind raspunzator de utilizarea
                  inregistrarii, indiferent daca utilizarea se face cu sau fara
                  acordul acestuia.
                </p>
                <p>
                  Art. 7.2. In cazul in care utilizatorul sesizeaza o utilizare
                  neautorizate a contului sau, este obligat sa sesizeze
                  administratorul site-ului SiteBunker in legatura cu orice
                  utilizare neautorizata a parolei si a inregistrarii personale.
                </p>
                <p>
                  Art. 7.3. Utilizatorul este de asemenea de acord sa foloseasca
                  butonul de iesire din contul personal cand se doreste
                  parasirea site-ului si a contului.
                </p>
                <p>
                  Art. 7.4. Societatea SiteBunker si administratorii site-ului
                  SiteBunker nu vor fi raspunzatori pentru nici o paguba morala
                  sau materiala provocata prin nerespectarea acestor prevederi
                  de catre utilizator.
                </p>
                <p>
                  Art. 7.5. Administratorii site-ului SiteBunker, vor putea
                  bloca parola, utilizareaserviciilor sau folosirea oricaror
                  altor servicii ale sale de catre utilizatori, sau sa scoata si
                  sa stearga orice material din cadrul serviciilor, pentru orice
                  motiv, fara a se limita la motivele prevazute la art.
                  8.10,8.11 din prezentul contract.
                </p>
                <p>
                  Art. 7.6. Orice incetare a accesului utilizatorului la
                  serviciile oferite pe site-ul SiteBunker, poate fi efectuata
                  fara o avertizare anticipata, administratorii site-ului
                  SiteBunker avand dreptul nelimitat de a dezactiva sau sterge
                  imediat contul utilizatorului din cadrul site-ului si toate
                  informatiile in legatura cu acesta si/sau sa interzica orice
                  acces ulterior la serviciile oferite de SiteBunker.
                </p>
                <p>
                  Art. 7.7. SiteBunker nu va fi raspunzatoare fata de utilizator
                  sau orice terta parte in orice mod, pentru interzicerea si/sau
                  stergerea contului si a accesului la serviciile puse la
                  dispozitie prin intermediul site-ului SiteBunker.
                </p>
                <p>8.DREPTURILE DE AUTOR SI DREPTURI DE MARCA</p>
                <p>
                  Art. 8.1. Intregul continut al site-ului SiteBunker, apartine
                  SiteBunker si este protejat conform legii dreptului de autor
                  si a legilor privind dreptul la proprietate intelectuala si
                  industriala. Folosirea fara acordul scris a Sitebunker a
                  oricaror elemente apartinand site-ului SiteBunker se
                  pedepseste conform legilor in vigoare.
                </p>
                <p>
                  Art. 8.2. Prin accesarea site-ului, nu i se acorda clientului
                  niciun drept asupra materialului de pe site, altul decat cele
                  pe care le implica folosirea normala a site-ului.
                </p>
                <p>
                  Art. 8.3. SiteBunker ii confera utilizatorului dreptul de a
                  descarca informatiile de pe site doar in vederea folosului
                  propriu, necomercial, cu conditia de a nu modifica simbolurile
                  si textul cu privire la copyright si drepturile de autor.
                </p>
                <p>
                  Art. 8.4. SiteBunker NU confera dreptul utilizatorului de a
                  modifica partial sau integral site-ul web, de a reproduce
                  partial sau integral site-ul, de a copia, vinde sau exploata
                  site-ul in orice alta maniera fara acordul SiteBunker.
                </p>
                <p>
                  Art. 8.5. Utilizatorul nu va modifica, copia, distribui,
                  transmite, afisa, publica, reproduce, acorda licente, crea
                  produse derivate, transfera sau vinde orice fel de informatie
                  sau servicii obtinute de pe sau prin intermediul acestui site
                  web.
                </p>
                <p>Art. 8.6.</p>
                <p>
                  Denumirea SiteBunker, logo-ul SiteBunker, sunt mărci
                  inregistrate de către EXIM HOST SRL. Alte mărci sunt de
                  asemenea menţionate pe site. Acestea sunt folosite de
                  SiteBunker, fie cu autorizaţia titularului, fie ca simplă
                  indicare a unor produse sau servicii comercializate si oferite
                  de SiteBunker.
                </p>
                <p>Art. 8.7.</p>
                <p>
                  Reproducerea, imitarea, utilizarea sau aplicarea acestor mărci
                  fără a fi obţinut in prealabil autorizaţie de la SiteBunker
                  sau de la titularii respectivi constituie infractiune si se
                  pedepseşte conform legilor in vigoare.
                </p>
                <p>
                  9.COMANDA, PLATI SI FACTURARE, VALOAREA CONTRACTULUI SI
                  EFECTUAREA PLATII
                </p>
                <p>Art. 9.1.</p>
                <p>
                  Orice comanda se va face online pe pagina web SiteBunker
                  https://sitebunker.ro, respectiv https://sitebunker.net.
                  Clientii se obliga sa introduca in formular date complete,
                  corecte si conforme cu realitatea. Inregistrarea domeniilor se
                  face in concordanta cu legile in vigoare. SiteBunker nu poate
                  fi trasa la raspundere sub nici o forma pentru neplaceri
                  cauzate de furnizarea de catre clienti a unor informatii
                  incorecte sau incomplete.
                </p>
                <p>
                  Comanda efectuata va fi confirmata printr-un mail care are
                  rolul de a instiinta clientul ca noua comanda a fost
                  inregistrata in sistemul nostru.
                </p>
                <p>
                  Efectuarea si inregistrarea comenzii in sistemul nostru nu
                  angajeaza obligatii contractuale pentru nici una din parti.
                  Clientul poate renunta la comanda prin neachitarea proformei
                  emise iar SiteBunker poate renunta la acceptarea si procesarea
                  comenzii prin anularea ei si returnarea sumei achitate (daca e
                  cazul).
                </p>
                <p>
                  Clientul este de acord ca aceasta confirmare a primirii
                  comenzii nu reprezinta o confirmare a validitatii comenzii sau
                  a procesarii serviciilor comandate, si nu genereaza obligatii
                  contractuale din partea SiteBunker.
                </p>
                <p>
                  Serviciile comandate vor fi confirmate si procesate doar dupa
                  efectuarea platii si verificarea efectuata de personalul
                  SiteBunker.
                </p>
                <p>
                  Art. 9.2. Clientul are obligatia sa informeze SiteBunker la
                  efectuarea comenzii sau ulterior daca e cazul, daca este
                  inregistrat in scopuri de TVA, conform ordinului
                  702/14.05.2012 pentru intocmirea Declaratiei 394 “Declaratie
                  informativa privind livrarile/prestarile si achizitiile
                  efectuate pe teritoriul national”, publicat in Monitorul
                  Oficial nr. 376/04.06.2007.
                </p>
                <p>
                  Art. 9.3. Informatii privind Protectia Datelor Personale:{" "}
                  <a href="https://sitebunker.ro/acord-prelucrare-datelor/">
                    https://sitebunker.ro/acord-prelucrare-datelor/
                  </a>
                </p>
                <p>
                  Clientul are obligatia sa citeasca Nota de informare privind
                  Protectia Datelor Personale inainte de a continua procesul de
                  comanda si trimitere a datelor catre EXIM HOST SRL.
                </p>
                <p>Art. 9.4.</p>
                <p>
                  Dupa finalizarea comenzii se trimite in mod automat o factura
                  proforma catre adresa de email introdusa in formular. Factura
                  proforma contine toate informatiile necesare efectuarii platii
                  si este disponibila online in contul de client. Factura
                  proforma nu are valoare contabila.
                </p>
                <p>
                  Toate proformele se emit in RON, calculate la cursul BNR+2%,
                  pe baza preturilor afisate pe site si a structurii comenzii.
                </p>
                <p>
                  Comanda se considera acceptata de catre SiteBunker dupa
                  procesarea sa in sistem.
                </p>
                <p>
                  Art. 9.5. Clientul este de acord sa achite contravaloarea
                  serviciilor noastre in avans pentru perioada de timp in care
                  acestea sunt furnizate. .
                </p>
                <p>
                  Art. 9.6. Toate facturile proforme se vor trimite prin email
                  iar o versiune printabila se va pune la dispozitia clientului
                  in panoul de control. Clientii trebuie sa achite
                  contravaloarea facturii in avans la inceputul fiecarei
                  perioade de plata, in termen de 5 zile lucratoare de la data
                  emiterii facturii proforme.
                </p>
                <p>
                  Art. 9.7. Dupa efectuarii platii si incasarea banilor se emite
                  factura fiscala. Facturile fiscale se expediaza in format
                  electronic “PDF” prin e-mail in conformitate cu Ordonanta de
                  urgenta nr. 106/2007, pentru modificarea si completarea Legii
                  nr. 571/2003 privind Codul fiscal publicat in Monitorul
                  Oficial, Partea I nr. 703 din 18/10/2007 la art 155 se adauga
                  urmatorul alineat : (11) Romania va accepta documentele sau
                  mesajele pe suport hartie ori in forma electronica drept
                  facturi.
                </p>
                <p>
                  Art. 9.8. Facturile fiscale emise dupa data de 1 iunie 2008
                  sunt disponibile in format PDF si in contul de client la
                  sectiunea “Facturi”.
                </p>
                <p>
                  Art. 9.9. SiteBunker recomanda confirmarea platilor prin
                  trimiterea unei copii (foto, scan) ce atesta acest lucru (cu
                  stampila bancii) prin e-mail (conform paginii noastre de
                  contact). Clientii beneficiaza de serviciile SiteBunker in
                  1-24 de ore de la confirmarea platii.
                </p>
                <p>
                  Art. 9.10. Suspendarea – Ne rezervam dreptul de a suspenda
                  orice cont dupa trecerea a 15 zile de la data scadentei de
                  plata a facturii proforme daca plata nu a fost efectuata.
                  Orice prelungire a termenului de plata este la discretia
                  noastra.
                </p>
                <p>Art. 9.11.</p>
                <p>
                  Stergerea Contului – Ne rezervam dreptul de a sterge definitiv
                  toate datele conturilor de gazduire web dupa trecerea a 30
                  zile de la data scadenta a facturii proforma daca plata nu a
                  fost efectuata. Orice prelungire a acestui termen este la
                  discretia noastra.
                </p>
                <p>
                  In cazul in care clientul solicita reluarea unui serviciu de
                  hosting dupa ce acesta a fost sters de pe server vom verifica
                  daca mai exista o copie de siguranta pe solutiile de back-up
                  existente. Daca aceasta copie exista se va proceda la emiterea
                  unei noi facturi de hosting pe acelasi pachet ca si cel
                  existent anterior, urmand ca dupa efectuarea platii sa fie
                  restaurat continutul.
                </p>
                <p>
                  Daca nu se doreste continuarea serviciului de hosting si se
                  solicita furnizarea unui back-up acesta se va furniza doar
                  dupa achitarea unei taxe in valoarea de 20 euro + TVA care sa
                  acopere stocarea si costul actiunii de furnizare a back-upului
                  de pe solutiile de stocare existente precum si livrarea in
                  format electoronic (link de descarcare) a copiei existente.
                </p>
                <p>
                  Nu oferim nici un fel de garantie privind existenta unei copii
                  de siguranta pe servere sau a integritatii acesteia.
                </p>
                <p>
                  Art. 9.12. Plata Numerar sau OP (Depunere Banca sau Transfer
                  Bancar) – Toate facturile si platile directe (numerar) sau
                  prin transfer bancar (OP, Depunere Numerarl) catre SiteBunker
                  sunt procesate in RON. Preturile pot fi afisate pe site sau
                  proforma in EUR si sa nu includa TVA.
                </p>
                <p>
                  Art. 9.13. Plata Online (Card Credit / Debit sau PayPal) –
                  Platile online cu cardul sunt efectuate in mod securizat prin
                  intermediul ING Bank (https://ing.ro) si Stripe
                  (https://stripe.com/). Platile prin Paypal sunt supuse unor
                  termeni si conditii. Va rugam cititi aceste termene si
                  conditii aici:
                  https://www.paypal.com/us/legalhub/useragreement-full
                </p>
                <p>
                  Art. 9.14. Plata pentru serviciile Servere Dedicate se
                  efectueaza in avans. Daca plata unui Server Dedicat nu este
                  efectuata in termen de 15 zile de la data scadenta a facturii,
                  serverul va fi inchis, fara a sterge de pe el datele
                  clientului. Stergerea datelor se va face la 30 de zile de la
                  data scadenta a facturii.
                </p>
                <p>
                  Art. 9.15. Pentru serviciile prestate, Clientul va achita
                  Prestatorului contravaloarea abonamentului, prevăzută în
                  momentul finalizării comenzii.
                </p>
                <p>
                  Art. 9.16. Prestatorul va trimite notificări de plată regulate
                  în conformitate cu tipul de abonament ales.
                </p>
                <p>10.INREGISTRAREA DOMENIILOR</p>
                <p>Art. 10.1.</p>
                <p>
                  Inregistrarea domeniilor sau rezervarea (in cazul domeniilor
                  RO) se face dupa primirea contravalorii facturii proforma
                  emise pentru serviciile de inregistrare indiferent de tipul
                  domeniului .ro, .eu, .com, etc.
                </p>
                <p>
                  Important: Domeniile odata inregistrate nu se pot strege sau
                  modifica indiferent de extensie!
                </p>
                <p>Art. 10.2.</p>
                <p>
                  SiteBunker nu este raspunzator pentru situatia in care intre
                  momentul efectuarii comenzii, a platii si efectuarea
                  inregistrarii domeniul, acesta este inregistrat de alta
                  persoana sau o alta firma de hosting.
                </p>
                <p>
                  In acest caz clientul poate solicita fie rambursarea sumei
                  achitate pentru domeniu, fie inregistrarea unui alt domeniu cu
                  aceeasi extensie.
                </p>
                <p>
                  Nu raspundem pentru neplata la timp a domeniilor care necesita
                  reinnoire, acesta putand duce la plata unei taxe de activare
                  sau la pierderea domeniului.
                </p>
                <p>
                  Taxele de activare depind de fiecare tip de domeniu si sunt
                  afisate pe SiteBunker.ro.
                </p>
                <p>
                  In cazul domeniilor .ro, sistemul nostru va efectua rezervarea
                  automata a domeniului in momentul efectuarii comenzii.
                  Domeniile .ro se rezerva pe 32 de zile la RoTLD, fara a exista
                  posibilitatea de stergere a rezervarii in acest interval. Prin
                  efectuarea unei comenzi a unui domeniu .ro va exprimati
                  acordul cu procedura de rezervare a domeniilor.
                </p>
                <p>
                  In cazul unor erori in procesul de rezervare a domeniilor .ro
                  in sistemul RoTLD vom face procesarea domeniului in momentul
                  platii sau a rezervarii domeniului manual in momentul
                  identificarii erorii. Nu ne asumam raspunderea si nu oferim
                  nici o garantie in acest sens pentru o eventuala inregistrare
                  a unui domeniu .ro de catre un tert in cazul in care
                  rezervarea automata a esuat, in timpul scurs pana la
                  procesarea manuala a rezervarii sau inregistrarii.
                </p>
                <p>
                  Pointarea domeniilor catre hosting se face prin intermediul
                  namserverelor, care se seteaza de catre client sau de catre
                  noi la cererea clientilor. Nu se ofera pentru domenii
                  posibilitatea de administrare avansata a zonei DNS decat in
                  cazul detinerii unui pachet de hosting, caz in care
                  administrarea avansata a zonei DNS se face prin intermediul
                  Cpanel.
                </p>
                <p>Art. 10.3.</p>
                <p>
                  In cazul domeniilor romanesti sau internationale, datele se
                  transmit catre autoritatile emitente a acestor domenii (ROTLD,
                  ICANN, Eurid, sau autoritati internationale care emit diverse
                  domenii). SiteBunker actioneaza ca partener al acestor
                  institutii preluand datele furnizate de client pentru
                  inregistrare si trimitandu-le in sistemul emitentilor pentru
                  inregistrare.
                </p>
                <p>
                  Regulile de afisare a datelor personale in cazul acestor
                  domenii revin acestor autoritati.
                </p>
                <p>
                  In cazul Rotld:{" "}
                  <a href="http://www.rotld.ro/">http://www.rotld.ro/</a>
                </p>
                <p>
                  In cazul Eurid:{" "}
                  <a href="http://www.eurid.eu/ro/">http://www.eurid.eu/ro/</a>
                </p>
                <p>
                  In cazul domeniilor internationale emise de ICANN, Procesate
                  prin intermenediul Demand Media INC, USA:{" "}
                  <a href="http://www.enom.com/terms/privacy.asp">
                    http://www.enom.com/terms/privacy.asp
                  </a>
                </p>
                <p>
                  Pentru domeniile procesate prin intermediul ENOM(com, net,
                  info, biz, org) exista posibilitatea ascunderii datelor
                  detinatorului prin optiunea ID Protect, disponibila contra
                  cost.
                </p>
                <p>
                  In cazul in care clientul nu este de acord cu regulile de
                  inregistrare a acestor domenii internationale inregistrarea nu
                  poate fi realizata.
                </p>
                <p>Art. 10.4.</p>
                <p>
                  Inregistrarea domeniilor se face in conformitate cu regulile
                  generale impuse de ICANN(Internet Corporation for Assigned
                  Names and Numbers). In functie de tipul domeniului,
                  administratorul TLD (Top Level Domain) impune reguli clare de
                  inregistrare si utilizare a domeniilor de internet.
                </p>
                <p>
                  ICANN Consensus Policies
                  <br />
                  SiteBunker inregistreaza aceste domenii prin partenerul sau
                  ENOM, clientii care inregistreaza domenii internationale sau
                  aleg sa le reinnoiasca trebuie sa respecte regulile de
                  inregistrare ale ENOM:{" "}
                  <a href="http://www.enom.com/terms/agreement.aspx">
                    http://www.enom.com/terms/agreement.aspx
                  </a>
                </p>
                <p>Important: Comanda si inregistrarea domeniilor PREMIUM</p>
                <p>
                  in cazul in care domeniul comandat de dvs este unul PREMIUM nu
                  vom putea face inregistrarea domeniului pt dvs. Determinarea
                  statutului PREMIUM al unui domeniu se determina in momentul
                  demararii procesarii inregistrarii domeniului de catre
                  personalul SiteBunker, sau automat de catre sistemele noastre.
                </p>
                <p>
                  In cazul in care domeniul comandat este unul PREMIUM va vom
                  contacta pentru a stabili fie returnarea sumei achitata de dvs
                  pentru comanda fie pentru a va comunica pretul la care se
                  poate face inregistrarea domeniul PREMIUM respectiv, sau
                  pentru a inregistra un alt domeniu cu aceeasi extensie.
                </p>
                <p>
                  Nota: Domeniile PREMIUM sunt domenii formate din putine litere
                  sau cu denumiri foarte cunoscute sau comune, dar fara a se
                  limita la aceste reguli. Statutul de PREMIUM al unui domeniu
                  este determinat si furnizat de sistemul ENOM prin verificari
                  punctuale si poate sa nu fie disponibil la efectuarea comenzii
                  ci doar la etapa verificarii acesteia de catre SiteBunker.
                </p>
                <p>Art. 10.5.</p>
                <p>
                  Inregistrarea Domeniilor .RO – SiteBunker este partener
                  RoTLD(Romanian Top Level Domain) si inregistrarea domeniilor
                  din Zona .RO presupune citirea si acceptarea tuturor regurilor
                  impuse si reglementate de RoTLD.
                </p>
                <p>
                  In conformitate cu Contractul de Parteneriat incheiat cu
                  RoTLD, art 4, alin a), SiteBunker afiseaza informatiile
                  privind inregistrarea domeniilor .ro, Regulile de
                  Inregistrare, Contractul de Inregistrare si restul
                  informatiilor privitoare la inregistrarea si folosirea unui
                  domeniu .ro.
                </p>
                <p>
                  Clientul este de acord sa consulte informatiile furnizate. In
                  cazul in care nu sunteti de acord cu regulile si conditiile
                  existente pentru inregistrarea si utilizarea domeniilor .ro nu
                  efectuati comanda. Finalizarea comenzii inseamna acceptarea
                  tuturor acestor termeni si conditii.
                </p>
                <p>
                  Datele de inregistrare a domeniilor sunt completate de client
                  la comanda si se transmit in mod automat prin API catre
                  autoritatea emitenta a domeniilor .ro, RoTLD, fara a se
                  interveni asupra lor in procesul de rezervare si inregistrare.
                </p>
                <p>
                  Reguli de Inregistrare
                  <br />
                  Contract pentru Inregistrare
                  <br />
                  In baza hotararii ICI RoTLD taxa de mentenanta anuala a fost
                  introdusa incepand cu 1 Martie 2018. Dobandirea dreptului de
                  folosinta a unui domeniu se face dupa achitarea taxei de
                  inregistrare. Prelungirea dreptului de folosinta a unui
                  domeniu se face prin achitarea taxei de mentenanta inainte de
                  expirarea perioadei valabile a dreptului de folosinta (data
                  expirare). Neplata taxei de mentenanta pana la data de
                  expirare duce la suspendarea si stergerea domeniului
                  respectiv.
                </p>
                <p>
                  SiteBunker, in calitate de partener (revanzator) al RoTLD,
                  ofera serviciul de inregistrare domenii in baza unui contract
                  de parteneriat cu RoTLD si se supune regulilor si deciziilor
                  RoTLD. SiteBunker nu garanteaza si nu poate garanta in nici un
                  fel ca oricare din conditiile existente la inregistrarea
                  domeniilor (termen de inregistrare, mod de administrare,
                  diverse taxe) nu vor fi modificate de catre RoTLD in orice
                  fel, inclusiv pentru domeniile deja inregistrate.
                </p>
                <p>
                  In cazul unor modificari care afecteaza detinatorii de domenii
                  .ro, SiteBunker va comunica prin intermediul site-ului si a
                  altor canale aflata la dispozitia sa informatiile puse la
                  dispozitie de RoTLD, in cel mai scurt timp posibil din care
                  acestea devin publice si SiteBunker a luat cunostinta de
                  aceste informatii.
                </p>
                <p>Art. 10.6.</p>
                <p>
                  Domenii .EU – SiteBunker furnizeaza servicii de inregistrare,
                  reinoire si transfer al domeniilor .EU in conformitate cu
                  regulile impuse de EURid (European Registry of Internet Domain
                  Names) iar inregistrarea domeniilor .EU presupune citirea si
                  acceptarea tuturor regurilor impuse si reglementate de EURid.
                </p>
                <p>
                  Politica de inregistrare .eu
                  <br />
                  Art. 10.7. Domenii Internationale – SiteBunker furnizeaza
                  servicii de inregistrare, reinoire si transfer al domeniilor
                  Internationale, prin parteneriatul cu eNom(eNom, Inc. Chicago,
                  IL), in conformitate cu regulile specifice fiecarui tip de
                  domeniu, reguli impuse de administratorii TLD (Top Level
                  Domain).
                </p>
                <p>
                  . COM – Domenii cu scop comercial. Se aplica regulile ICANN –
                  COM.
                  <br />. NET – Domenii pentru retele, general sau comercial. Se
                  aplica regulile ICANN – NET.
                  <br />. ORG – Domenii pentru organizatii sau comercial. Se
                  aplica regulile ICANN – ORG.
                  <br />. BIZ – Domenii pentru firme sau uz comercial. Se aplica
                  regulile ICANN – BIZ.
                  <br />. INFO – Domenii cu scop general. Se aplica regulile
                  ICANN – INFO.
                  <br />. US – Domenii doar pentru Statele Unite ale Americii.
                  Se aplica regulile usTLD.
                  <br />. UK – Domenii doar pentru Regatul Unit al Marii
                  Britanii. Se aplica regulile Nominet.
                  <br />. IN – Domenii India. Poate fi inregistrat indiferent de
                  tara.
                  <br />. NAME – Domenii pentru nume propri. Se aplica regulile
                  ICANN – NAME.
                  <br />. MOBI – Domenii dispozitive mobile. Detalii MOBI.
                  <br />
                  Art. 10.8.
                </p>
                <p>
                  In cazul in care domeniul comandat de client nu va putea fi
                  inregistrat din oricare motiv ( date de identificare
                  incomplete sau gresite, indisponibilitate, reguli impuse de
                  TopTLD si care nu sunt respectate de viitorul detinator al
                  domeniului, domenii premium, cerinte privind tara de
                  rezidenta,statutul juridic, domeniul de activitate, alte
                  situatii) comanda nu va fi acceptata si va fi anulata iar
                  sumele achitate de client pentru inregistrarea domeniului vor
                  fi returnate.
                </p>
                <p>11.PROTECTIA DATELOR PERSONALE</p>
                <p>Art. 11.1.</p>
                <p>
                  Conform cerintelor Legii nr. 677/2001 pentru protectia
                  persoanelor cu privire la prelucrarea datelor cu caracter
                  personal si libera circulatie a acestor date, modificata si
                  completata si ale Legii nr. 506/2004 privind prelucrarea
                  datelor cu caracter personal si protectia vietii private in
                  sectorul comunicatiilor electronice, EXIM HOST SRL are
                  obligatia de a administra in conditii de siguranta si numai
                  pentru scopurile specificate, datele personale pe care ni le
                  furnizati despre dumneavoastra, un membru al familiei
                  dumneavoastra ori o alta persoana.
                </p>
                <p>
                  Scopul colectarii datelor este: furnizarea serviciilor si
                  produselor conform ofertei curente, precum si inregistrarea
                  domeniilor web in numele clientilor si conform comenzilor
                  acestora.
                </p>
                <p>
                  Sunteti obligat(a) sa furnizati datele, acestea fiind necesare
                  pentru a putea identifica destinatarul serviciilor sau
                  produselor furnizate, ori pentru inregistrarea domeniilor pe
                  numele dvs (in calitate de client). Refuzul dvs. determina
                  imposibilitatea furnizarii serviciului sau produsului, ori
                  inregistrarea domeniilor comandate.
                </p>
                <p>
                  Informatiile inregistrate sunt destinate utilizarii de catre
                  operator si, dupa caz, sunt comunicate numai urmatorilor
                  destinatari: autoritatile nationale/europene/mondiale de
                  inregistrare/administrare a domeniilor web sau partenerilor
                  autorizati ai acestora (ROTLD, EUrid, Enom).
                </p>
                <p>
                  In cazul domeniilor internationale, datele se transmit catre
                  autoritatile emitente a acestor domenii (ICANN, Eurid, sau
                  autoritati internationale care emit diverse domenii). Regulile
                  de afisare a datelor personale in cazul acestor domenii revin
                  acestor autoritati:
                </p>
                <p>Domenii . EU</p>
                <p>
                  In cazul Eurid (sectiunea Politica WHOIS):{" "}
                  <a href="https://eurid.eu/ro/">https://eurid.eu/ro/</a>
                </p>
                <p>
                  Regulile Eurid de inregistrare a domeniilor .EU preuspun
                  afisarea datelor de contact ale detinatorului domeniilor la
                  sectiunea Whois a eurid.eu, fara posibilitatea de ascundere a
                  datelor.
                </p>
                <p>Domenii internationale .COM, .NET, .ORG, .INFO etc</p>
                <p>
                  In cazul domeniilor internationale emise de ICANN, Procesate
                  prin intermenediul Demand Media INC, USA:{" "}
                  <a href="http://www.enom.com/terms/privacy.asp">
                    http://www.enom.com/terms/privacy.asp
                  </a>
                </p>
                <p>
                  Regulile pentru domeniile ICANN – ENOM presupun afisarea
                  datelor detinatorului domeniului la cautarile Whois.
                </p>
                <p>
                  Pentru domeniile procesate prin intermediul ENOM exista
                  posibilitatea ascunderii datelor detinatorului prin optiunea
                  ID Protect, disponibila contra cost.
                </p>
                <p>
                  Important: In cazul in care clientul nu este de acord cu
                  regulile de inregistrare a acestor domenii internationale
                  inregistrarea nu poate fi realizata.
                </p>
                <p>
                  Conform Legii nr. 677/2001, beneficiati de dreptul de acces,
                  de interventie asupra datelor, dreptul de a nu fi supus unei
                  decizii individuale si dreptul de a va adresa justitiei.
                </p>
                <p>
                  Totodata, aveti dreptul sa va opuneti prelucrarii datelor
                  personale care va privesc si sa solicitati stergerea datelor*.
                </p>
                <p>
                  Pentru exercitarea acestor drepturi, va puteti adresa cu o
                  cerere scrisa, datata si semnata la contact@SiteBunker.ro.
                </p>
                <p>
                  De asemenea, va este recunoscut dreptul de a va adresa
                  justitiei.
                </p>
                <p>
                  Datele dumneavoastra vor fi transferate in strainatate,
                  respective USA si Belgia, in vederea inregistrarii domeniilor
                  intermationale (daca este cazul).
                </p>
                <p>
                  Daca unele din datele despre dumneavoastra sunt incorecte, va
                  rugam sa ne informati cat mai curand posibil.
                </p>
                <p>
                  Observatie: *orice persoana are dreptul de a se opune, pentru
                  motive legitime, la prelucrarea datelor ce o privesc. Acest
                  drept de opozitie poate fi exclus pentru anumite prelucrari
                  prevazute de lege (de ex.: prelucrari efectuate de serviciile
                  financiare si fiscale, de politie, justitie, securitate
                  sociala). Prin urmare, aceasta mentiune nu poate figura daca
                  prelucrarea are un caracter obligatoriu; orice persoana are,
                  de asemenea, dreptul de a se opune, in mod gratuit si fara
                  nici o justificare, la prelucrarile datelor sale personale in
                  scopuri de marketing direct.
                </p>
                <p>Art. 11.2.</p>
                <p>
                  Prin acceptarea acestui contract va informam ca datele
                  furnizate la inregistrarea domeniilor si datele furnizate
                  pentru facturarea serviciilor furnizate vor fi transmise
                  autoritatilor care au dreptul legal de a le solicita, in
                  cazurile in care vor fi solicitate de acestea, in baza unor
                  solicitari primite de la autoritatile respective.
                </p>
                <p>12.GARANTII SI RETURNAREA BANILOR</p>
                <p>Art. 12.1.</p>
                <p>SiteBunker ofera posibilitatea de retur in 14 zile!</p>
                <p>
                  Dreptul de retragere este garantat consumatorilor in baza OUG
                  34/2014
                </p>
                <p>
                  Clientii au dreptul la rambursul sumei platite pentru
                  serviciile de gazduire web in termen de 14 zile calendaristice
                  (conform OUG 34/2014) de la achitarea pachetului de gazduire,
                  fara a preciza motivele. Clientii ce doresc rambursul platii
                  pentru serviciile de gazduire trebuie sa solicite printr-o
                  declaratie neechivoca acest lucru printr-un email catre
                  [[ANTISPAM]] sau prin sistemul integrat de suport. Cererea
                  trebuie sa contina datele de identificare ale clientului,
                  confirmarea ca toate datele de pe server au fost salvate,
                  optional motivele pentru care solicita returnarea banilor,
                  precum si informatiile necesare operatiunii de retur.
                </p>
                <p>
                  Daca clientul a achizitionat si un domeniu impreuna cu
                  pachetul de hosting si acesta a beneficiat de o oferta de
                  reducere, din suma totala achitata se va scadea valoarea
                  intreaga a domeniului (la pretul de inregistrare fara
                  reducere). Domeniul va ramane in proprietatea clientului pana
                  la expirare, in functie de conditiile de inregistrare
                  specifice fiecarui TLD.
                </p>
                <p>
                  Din suma returnata se scad Comisioanele Bancare si cele pentru
                  Procesarea Platii cu Cardul (daca este cazul). Plata sumei
                  returnate minus comisioane va fi facuta in maxim 30 de zile de
                  la data primirii cererii de rambursare.
                </p>
                <p>
                  Clientul trebuie sa salveze toate datele din contul de
                  gazduire web inainte de a efectua cererea de ramburs. Din
                  cPanel se poate genera si descarca o arhiva ce cuprinde toate
                  datele din cont in mod automat.
                </p>
                <p>
                  Plata pentru inregistrarea domeniilor nu poate fi returnata
                  din momentul in care domeniul a fost inregistrat, acesta intra
                  in proprietatea clientului pana la expirarea termenului de
                  inregistrare.
                </p>
                <p>
                  Nu fac obiectul garantiei de de returnare urmatoarele:
                  inregistrarea domeniilor, certificate de securitate, licente
                  sau servere dedicate, orice alte servicii sau produse care
                  sunt individualizate la solicitarea clientului (inregistrare
                  domeniu, activare pe baza de IP in afara serviciilor de
                  hosting shared, alocare pe 1 anumit domeniu a unei licente)
                  conform regementarilor OUG 34/2014, Art 16, aliniat C, privind
                  furnizarea de produse sau servicii personalizate.
                </p>
                <p>Art. 12.2.</p>
                <p>
                  Returnarea nu poate fi oferita in anumite cazuri si pentru
                  anumite servicii. Plata pentru inregistrarea domeniilor,
                  licente, reduceri sau alte gratuitati nu este rambursabila. In
                  cazul solicitarii de ramburs pentru pachetele de gazduire web
                  pretul domeniului, licentelor, reducerilor sau gratuitatile
                  vor fi scazute din suma totala. Aceasta regula se aplica si
                  pentru clientii care au primit domeniul gratuit, o licenta,
                  reducere sau gratuitate in urma unei oferte promotionale.
                </p>
                <p>
                  Plata pentru inregistrarea domeniilor nu poate fi returnata
                  din momentul in care domeniul a fost inregistrat, domeniul
                  intra in proprietatea clientului pana la expirarea termenului
                  de inregistrare.
                </p>
                <p>
                  Deoarece serviciile speciale cum sunt Servere Dedicate au un
                  cost mare de punere in functiune plata nu poate fi rambursata,
                  serverele vor ramane in functiune pana la expirarea
                  termenului.
                </p>
                <p>
                  Art. 12.3. Garantia de functionare a serviciilor SiteBunker
                </p>
                <p>
                  In cazul nefunctionarii (downtime) a serverelor / serviciilor
                  oferite de EXIM HOST SRL pe o durata mai mare de 1% dintr-o
                  luna, clientul are dreptul la o luna gratuita de gazduire web
                  in plus. Valoarea despagubirilor nu pot sa depaseasca valoarea
                  lunara sau totala platita de client pentru serviciul de
                  gazduire. Despagubirile se ofera doar la cererea clientului
                  sub forma servicii de gazduire gratuite acordate sub forma de
                  luni. Nefunctionarea pachetelor de gazduire trebuie sa fie din
                  cauze hardware sau software ce tin de echipamentul si
                  infrastructura EXIM HOST SRL si nu se aplica pentru factori
                  externi cum sunt: conexiuni la provideri de internet,
                  furnizorul de electricitate, gaze, utiliati sau cazuri
                  speciale de urgenta dispuse in legile in vigoare.
                </p>
                <p>
                  Art. 12.4. Garantia de ramburs nu se aplica daca clientul nu
                  respecta termenii din Contractul de furnizare a serviciilor
                  EXIM HOST SRL.
                </p>
                <p>13.UTILIZAREA SERVICIILOR</p>
                <p>Art. 13.1.</p>
                <p>
                  Serviciile oferite de SiteBunker vor fi utilizate numai in
                  scopuri legale. Orice activitati ce pot fi considerate ilegale
                  sunt strict interzise.
                </p>
                <p>Art. 13.2.</p>
                <p>
                  SiteBunker isi rezerva dreptul sa stearga sau sa blocheze
                  accesul la continutul de pe site sau de pe server fara o
                  notificare prealabila. Dreptul de utilizare a serviciilor
                  SiteBunker va fi restabilit dupa intrarea in legalitate a
                  posesorului contului.
                </p>
                <p>
                  Art. 13.3. In cazul in care un cont revandut incalca
                  prevederile din acest document, ne rezervam dreptul de a
                  suspenda acest cont fara a informa in prealabil pe reseller.
                  Utilizatorul final nu va fi contactat de catre noi, urmand ca
                  resellerul (clientrul SiteBunker) sa fie informat asupra
                  faptelor si motivelor noastre.
                </p>
                <p>Art. 13.4. UTILIZAREA SPATIULUI</p>
                <p>
                  Spatiul oferit poate fi folosit doar pentru stocarea
                  fisierelor folosite la site (fisiere html, documente,
                  scripturi, imagini, fisiere multimedia, email-uri) sau au
                  legatura cu activitatea sitului sau a companiei. Nu este
                  permisa stocarea de fisiere pentru filesharing, torrents, mass
                  downloads etc.
                </p>
                <p>
                  Art. 13.5. Serverele noastre nu vor fi utilizate sub nici o
                  forma si in nici o circumstanta pentru transmiterea, stocarea
                  sau publicarea de materiale ilegale (incluzand legislatia din
                  Romania, Uniunea Europeana si S.U.A.). Materialele ilegale
                  includ, dar nu sunt limitate la:
                </p>
                <p>
                  Incalcarea unui drept de autor sau al oricarui alt drept al
                  oricarui tert
                  <br />
                  Materiale sau Legaturi catre de filme, canale tv, mp3 sau alte
                  formate media
                  <br />
                  Promovarea de activitati ilegale (hacking, cracking, warez,
                  etc)
                  <br />
                  Materiale protejate de secrete de marca sau de vreun alt
                  statut
                  <br />
                  Amenintari, abuz, hartuire, declaratii calomnioase
                  <br />
                  Materiale menite sa incurajeze sentimentul de ura sau
                  discriminare
                  <br />
                  Continut pentru adulti, nuditate, pornografie, orice imagine
                  sau text cu continut sexual sau obscen
                  <br />
                  Informatii sau software despre sau continand orice tip de
                  virusi sau troieni
                  <br />
                  Colectarea de informatii personale pentru utilizarea in
                  scopuri ilegale
                  <br />
                  Date cu caracter personal fara acordul in scris a persoanelor
                  in cauza
                  <br />
                  Orice continut considerat de noi ca fiind daunator sau ilegal
                  <br />
                  Art. 13.6.
                </p>
                <p>
                  Pornografia sau orice alte materiale cu continut sexual,
                  erotic, nuditate sau obscen, link-uri, redirectari sau alte
                  medii avand legatura cu pornografia, sunt interzise pe
                  serverele noastre. SiteBunker isi rezerva dreptul de a decide
                  in ceea ce priveste legalitatea acestor situri si de blocarea
                  accesului public sau suspendatea serviciilor.
                </p>
                <p>
                  Art. 13.7. Nu este permisa utilizarea urmatoarelor scripturi
                  pe serverele noastre:
                </p>
                <p>
                  Scripturi pentru proxy sau IRC
                  <br />
                  Scripturi pentru Torrent Tracker sau fisiere Torrent
                  <br />
                  Scripturi pentru Top-uri sau Statistici
                  <br />
                  Scripturi pentru File Sharing
                  <br />
                  Scripturi pentru Matrimoniale sau Intermedieri Swing, Sexual,
                  Erotic, Adult
                  <br />
                  Scripturi pentru Retele de Socializare, Comunitati On-line
                  <br />
                  Scripturi pentru Redirectare Url (url shortener)
                  <br />
                  PhpShell si scripturi similare pentru executarea de comenzi
                  <br />
                  Scripturi de tipul FormMail
                  <br />
                  Scripturi Chat, Live Suport sau ori ce alt sistem de chat /
                  discutii in direct
                  <br />
                  Scripturi de distributie (hostate sau legaturi) media, video,
                  muzica, live stream
                  <br />
                  Scripturi (comerciale sau necomerciale) ce contin gauri de
                  securitate cunoscute
                  <br />
                  Scripturi comerciale (platite sau gratuite) neactualizate la
                  ultima versiune disponibila
                  <br />
                  Scripturi pentru streaming
                  <br />
                  14.POLITICA DE TRIMITERE A MESAJELOR
                </p>
                <p>
                  Art. 13.8. Prestatorul is rezerva dreptul de a suspenda in
                  termen de 12 ore de la notificarea clientului orice Website ce
                  promoveaza stiri false (fake news). Orice pagina sau material
                  care in urma unei sesizari sau analize interne este
                  identificat ca promotor de stiri false, ne rezervam dreptul de
                  a bloca accesul publicului sau suspendatea serviciilor.
                </p>
                <p>
                  Art. 14.1. Trimiterea de mesaje e-mail cu scop comercial,
                  publicitare sau in masa catre adrese e-mail o singura data sau
                  in mod regulat trebuie sa respecte conditiile impuse de noi.
                  Astfel de campanii pot fi operate doar cu liste e-mail in care
                  persoanele se inscriu personal prin acord expres,(conform
                  legii 365/2002 Art 6, alin 1 si 2) pentru a primi astfel de
                  mesaje iar in toate mesajele trebuie publicata procedura de
                  retragere din liste. Orice reclamatie primita in acest sens va
                  fi investigata si se vor lua masurile ce se impun.
                </p>
                <p>
                  Art. 14.2. Conditii ce trebuiesc respectate la trimiterea
                  mesajelor comerciale,publicitare sau in masa:
                </p>
                <p>
                  – Campaniile de email marketing sau emailuri in masa sunt
                  permise doar daca clientul detine o adresa IP Dedicata.
                </p>
                <p>
                  – Orice lista de email-uri mai mare de 2000 de adrese necesita
                  o solutie specializata Dedicata.
                </p>
                <p>
                  – Trimiterea mesajelor este permisa doar in intervalul orar
                  00:00 – 08:00.
                </p>
                <p>
                  – Trimiterea de mesaje trebuie impartita astfel incat sa nu se
                  trimita mai mult de 100 mesaje / ora. (trimiterea de valori
                  mai mari duce la blocarea expedierii tuturor mesajelor de
                  catre servere ca yahoo, google, hotmail)
                </p>
                <p>
                  – Trimiterea de mesaje e-mail in masa trebuie facuta printr-un
                  script ce foloseste conectarea SMTP.
                </p>
                <p>
                  – Folosirea sendmail din CGI sau PHP “mail()” este interzisa
                  pentru trimiterea de mesaje e-mail in masa.
                </p>
                <p>
                  – Adresele trecute la Return-path:, From:, Reply-To: trebuie
                  sa functioneze si sa aiba alocat spatiu suficient.
                </p>
                <p>
                  – Trimiteti mesaje doar persoanelor care si-au dat acordul
                  expres de a primii mesaje prin e-mail.
                </p>
                <p>
                  – Arhivarea datelor introduse de catre destinatar la
                  inscrierea in listele de e-mail incluzand data si ora
                  inscrierii, adresa IP si datele despre vizita.
                </p>
                <p>
                  – Includeti in fiecare e-mail trimis datele complete si
                  adevarate de identificare a expeditorului. Includeti in
                  fiecare e-mail posibilitatea de a renunta la primirea de
                  mesaje e-mail, ce trebuie si respectata.
                </p>
                <p>
                  – Listele de adrese e-mail trebuie mentinute la zi,
                  asigurativa ca aveti resursele de face acest lucru.
                </p>
                <p>
                  Art. 14.3. Orice sesizare primita in acest sens si confirmata
                  va duce la suspendarea imediata a contului respectiv fara o
                  anuntare in prealabil a detinatorului contului de hosting sau
                  resellerului.
                </p>
                <p>15.POLITICA ANTI SPAM</p>
                <p>
                  Art. 15.1. Trimiterea de mesaje comerciale, publicitare sau
                  nesolicitate denumite generic SPAM pentru promovarea oricarui
                  site utilizand serverele noastre sau prin alte servere pentru
                  promovarea oricarui site gazduit pe serverele noastre va avea
                  ca si consecinta suspendarea sau anularea contului de gazduire
                  al clientului, fara drept la ramburs. Pot fi operate liste
                  email in conditiile in care persoanele se inscriu pentru a
                  primi mesaje prin acceptul personal expres (conform legii
                  365/2002 Art 6, alin 1 si 2) si se publica in toate mesajele
                  procedura de retragere din liste. Orice reclamatie primita in
                  acest sens va fi investigata si se vor lua masurile ce se
                  impun.
                </p>
                <p>Art. 15.2. Un mesaj poate fi considerat SPAM chiar daca:</p>
                <p>
                  – Are trecuta fraza “Acest e-mail nu poate fi considerat SPAM
                  …” sau alte fraze asemanatoare.
                </p>
                <p>
                  – Daca datele de indentificare a expeditorului sunt incomplete
                  sau incorecte.
                </p>
                <p>
                  – Daca nu puteti demonsta ca o anumita persoana a fost de
                  acord sa primeasca mesaje e-mail de la dvs.
                </p>
                <p>
                  – Daca procedura de retragere din baza de e-mailuri nu
                  functioneaza.
                </p>
                <p>
                  – Daca folositi liste de e-mail cumparate (chiar si cu
                  factura!) sau primite la schimb.
                </p>
                <p>– Mesajul e-mail este trimis de pe alt server.</p>
                <p>
                  Art. 15.3. Orice sesizare primita in acest sens si confirmata
                  va duce la suspendarea imediata a contului respectiv fara o
                  anuntare in prealabil a detinatorului contului de hosting sau
                  resellerului.
                </p>
                <p>
                  Art. 15.4. La prima incalcare a regulilor de trimitere a
                  mesajelor comerciale nesolicitate denumite generic SPAM
                  stipulate in contract, serviciile de gazduire vor fi
                  suspendate sau trimiterea de mesaje va fi limitata. Deblocarea
                  sau ridicarea limitelor se face doar dupa trimiterea catre
                  EXIM HOST SRL prin fax sau e-mail a unui declaratii pe proprie
                  raspundere semnata, datata si stampilata (in cazul firmelor)
                  cu textul “Am citit, inteleg si sunt de acord cu politicile de
                  trimitere a mesajelor e-mail stipulate in Contractul de
                  Furnizare a Serviciilor, EXIM HOST SRL.” insotita de o copie
                  dupa actul de identitate sau a cerfificatului de inregistrare
                  in cazul firmelor.
                </p>
                <p>
                  Art. 15.5. Folosirea form-urilor online pentru expedierea de
                  e-mailuri se face doar prin scripturi securizate la care
                  accesul este restrictionat de parola sau sisteme anti robot.
                  Nerespectarea acestor prevederi duce la dezactivarea
                  scripturilor sau suspendarea contului.
                </p>
                <p>16.UTILIZAREA RESURSELOR</p>
                <p>
                  Art. 16.1. Toate conturile sunt monitorizate pentru a urmari
                  consumul de resurse de pe server. Daca un cont utilizeaza o
                  cantitate foarte mare de resurse, vom contacta clientul
                  respectiv pentru a discuta in aceasta privinta. In cazul in
                  care un cont utilizeaza o cantitate mult prea mare de resurse
                  (memorie, procesor), contul poate fi suspendat sau scriptul
                  dezactivat fara informarea prealabila a clientului, pentru a
                  mentine buna functionare a serverului. In cadrul pachetelor de
                  gazduire web si reseller “Shared Hosting” nu se vor accepta
                  sub nici o forma utilizatori mari consumatoari de resurse.
                  SiteBunker supravegheaza respectarea celor de mai sus prin
                  monitorizarea rapoartelor despre resursele utilizate.
                </p>
                <p>
                  Art. 16.2. Clientii pot sa solicite imbunatatirea pachetului
                  sau marirea temporara a resurselor alocate. Constituie
                  responsabilitatea clientului sa ne contacteze din timp pentru
                  a solicita marirea resurselor pentru a evita astfel
                  suspendarea contului. In unele cazuri conturile sunt
                  suspendate automat cand se atinge limita resurselor alocate,
                  pentru reactivare fiind necesara interventia manuala din
                  partea noastra.
                </p>
                <p>17.RESTRICTII, LIMITARI SI ACTUALIZARI</p>
                <p>
                  Art. 17.1. SiteBunker actualizeaza periodic fara o notificare
                  prealabila softurile / programele de system ce ruleaza pe
                  servere (exemple: Sistemul de Operare, cPanel (incluzand
                  softurile aditionale), PHP, MySQL, FTP, Apache (Server Web),
                  Exim (Server Mail) etc…) la versiuni stabile puse la
                  dispozitie de catre producatorii respectivelor softuri.
                </p>
                <p>
                  In anumite conditii este posibil ca functionalitati si/sau
                  softuri si programe oferite sa fie scoase definitiv sau
                  inlocuite cu altele pentru acoperirea unor gauri de
                  securitate, erori de proiectare si a asigura functionarea in
                  siguranta a serverelor.
                </p>
                <p>
                  Art. 17.2. Ne rezervam dreptul de a impune restrictii hardware
                  si software in folosirea serviciilor SiteBunker fara vre1
                  anunt prealabil, in cazul unor probleme de securitate
                  cunoscute ce pot duce la functionarea defectuoasa a serverelor
                  sau exploatarea programelor instalate pe server, chiar daca
                  acest lucru duce la blocarea accesului permanent au temporar
                  la serviciile de gazduire web.
                </p>
                <p>
                  Art. 17.3. In cazul in care SiteBunker descopera in urma
                  monitorizarii serverelor sau in urma unei sesizari un cont in
                  care se desfasoara activitati ilegale sau fisiere ce pot fi
                  folosite la activitati ilegale, serviciile vor fi limitate,
                  blocate sau suspendate. SiteBunker va notifica clientul si va
                  face trimiteti cu exemple de fisiere sau softuri din
                  interiorul contului de gazduire. Este probabil ca activitatile
                  ilegale sa fie desfasurate de atacatori / hackeri ce
                  speculeaza softul folosit la site sau parole salvate pe
                  calculator.
                </p>
                <p>Art. 17.4.</p>
                <p>Protectia automata Anti Malware si Antivirus</p>
                <p>
                  SiteBunker foloseste softuri speciale pentru a detecta
                  atacurile si infectiile cu malware. Aceste softuri
                  specializate efectueaza scanari permanente asupra conturilor
                  si a activitatii din aceste conturi pentru a preveni
                  infectiile cu virusi si malware.
                </p>
                <p>
                  Fisierele infectate identificate vor fi plasate automat in
                  carantina pentru a asigura protectia contului si a tertilor
                  care acceseaza site-ul in cauza. Probabilitatea de a afecta
                  functionarea site-urilor infectate este minima, totusi in
                  anumite situatii, daca sunt infectate fisiere importante ( ex:
                  index.html) aceasta poate duce la disfunctionalizati a
                  site-ului.
                </p>
                <p>
                  Clientii au obligatia sa ne notifice imediat asupra
                  constatarii oricaror disfunctionalitati observate, pentru a
                  verifica daca aceasta se datoreaza unor infectii malware. In
                  aceste conditii SiteBunker va furniza lista de fisiere aflate
                  in carantina clientului pentru curatarea acestora.
                </p>
                <p>
                  Fisierele aflate in carantina sunt pastrate timp de 30 de zile
                  pe server, dupa care sunt sterse automat de sistem.
                </p>
                <p>
                  Art. 17.5. Redirectarea mailurilor (Forward) catre anumiti
                  providerii de servicii de mail gratuite cum sunt: yahoo,
                  hotmail, gmail, etc. este INTERZISA si blocata pe serverele
                  noastre. Ne rezervam dreptul de a bloca sau sterge orice
                  redirectare de acest gen, datorita politicii acestor provideri
                  de a bloca Ip-ul serverului de pe care se fac acest gen de
                  redirectari.
                </p>
                <p>Art. 17.6.</p>
                <p>Limitari si Functii oprite Apache / PHP:</p>
                <p>disable_functions:</p>
                <p>
                  system, exec, passthru, proc_open, popen, shell_exec, symlink,
                  link, dl, syslog, pcntl_exec, pcntl_fork, pcntl_signal,
                  pcntl_waitpid, pcntl_wexitstatus, pcntl_wifexited,
                  pcntl_wifsignaled, pcntl_wifstopped, pcntl_wstopsig,
                  pcntl_wtermsig
                </p>
                <p>Art. 17.7.</p>
                <p>
                  Numarul de mesaje e-mail ce pot fi trimise din fiecare cont
                  este limitat dupa cum urmeaza:
                </p>
                <p>
                  Toate pachetele
                  <br />
                  200 / ora
                  <br />
                  NOTA Headerele mesajelor trimise fara autentificare, functia
                  mail(), nu pot fi rescrise!
                </p>
                <p>
                  NOTA IP-ul DEDICAT se achizitoneaza separat pentru fiecare
                  pachet de gazduire (cont cPanel)!
                </p>
                <p>18.CONDITII SPECIALE SERVERE DEDICATE</p>
                <p>
                  Art. 18.1. Platile efectuate pentru Servere dedicate nu se
                  returneaza.
                </p>
                <p>
                  Art. 18.2. Serverele sunt oferite fara management, clientul
                  este responsabil pentru administrarea serverului propriu.
                </p>
                <p>
                  Art. 18.3. Clientul are responsabilitatea efectuarii de
                  back-up pentru datele existente pe server. SiteBunker nu ofera
                  servicii de backup pentru servere si nici o garantie privind
                  integritatea datelor existente pe server.
                </p>
                <p>Art. 18.4.</p>
                <p>
                  Plata pentru serviciile Servere VPS se efectueaza in avans.
                  Plata pentru serverele VPS trebuie efectuata pana cel tarziu
                  la data expirarii serviciului (data scadenta a serviciului).
                  Daca nu este efectuata plata pana la aceasta data, serverul va
                  fi inchis si sters definitiv, inclusiv toate datele existente
                  pe server, acestea nu vor mai putea fi recuperate dupa
                  stergere.
                </p>
                <p>19.CONDITII SUPORT TEHNIC</p>
                <p>
                  Suportul tehnic oferit este de tipul “best effort”. SiteBunker
                  nu ofera garantii privind timpii de raspuns si solutionarea
                  cererilor adresate catre sistemul de suport.
                </p>
                <p>Suportul tehnic este oferit pentru :</p>
                <p>
                  utilizarea serviciilor de hosting
                  <br />
                  utilizarea serviciilor de email (nu oferim suport pentru
                  setarile softurilor de email (ex: Outlook, Thunderbird, iPhone
                  Mail, Android Email, etc.))
                  <br />
                  Suportul tehnic NU este oferit pentru :
                </p>
                <p>
                  creearea si administrarea paginilor web
                  <br />
                  utilizarea de softuri pentru trimiterea de email-uri
                  <br />
                  verificare si reparare erori de functionare a site-urilor
                  <br />
                  softurile gratuite puse la dispozitia clientilor (ex:
                  SiteBuilder, Softaculous, etc.)
                  <br />
                  Suportul oferit contra cost
                </p>
                <p>
                  SiteBunker poate oferi suport contra cost la solicitarea
                  clientilor pentru situatii tehnice specifice exclusiv pentru
                  serviciile care sunt gazduite pe serverele noastre.
                </p>
                <p>
                  In cazul servciilor de instalare certificate SSL acestea se
                  ofera contra cost, sunt valabile exclusiv pentru conturile
                  gazduite pe serverele noastre si se refera la instalarea
                  certificatului pe server si nu includ setarile necesare la
                  nivel de site. Modificarile care trebuie efectuate in site
                  sunt strict responsabilitatea clientului.
                </p>
                <p>20.BACK-UP</p>
                <p>
                  Art. 20.1. Clientii au obligatia de a salva periodic copii de
                  siguranta (backup) a datelor stocate in pachetele de gazduire
                  pe medii de proprii. In acest scop softul cPanel ofera unelte
                  de generare de arhive ce contin datele (fisiere, mailuri, baze
                  de date) si setarile pachetului de gazduire in mod automat sau
                  manual prin FTP, PhpMyAdmin sau FileManager in functie de
                  preferinte. Clientii trebuie sa se asigure ca dispun de
                  suficient spatiu in pachetul de gazduire pentru generarea
                  copiilor de siguranta, ca sunt descarcate pe un mediu de
                  stocare propriu iar datele salvate sunt integre si
                  utilizabile.
                </p>
                <p>Art. 20.2.</p>
                <p>
                  Serviciile de back-up (copii de siguranta si protectia
                  datelor) sunt implementate de catre SiteBunker pe mai multe
                  medii de stocare si puncte de restore care pot sa difere in
                  functie de pachet sau server. Protectia fizica a hard
                  disk-urilor este asigurata prin RAID, conturile de gazduire
                  sunt salvate peste noapte (intervalul de rulare este in funtie
                  de tipul pachetului) pe medii de stocare dedicate acestui
                  lucru prin cpbackup (cPanel), pe servere externe de storage
                  prin intermediul unor softuri specializate si un backup extern
                  ce poate fi folosit doar in cazuri de forta majora.
                </p>
                <p>
                  Desi investim permanent in tehnologie si resurse, nu putem sa
                  oferim nicio garantie in legatura cu functionarea corecta a
                  sistemelor de backup, existanta datelor de backup sau de
                  integritatea datelor stocate in scop de backup. Deoarece in
                  anumite cazuri si conjuncturi datele pot fi pierdute fara
                  posibilitatea de a fi recuperate, clientii au obligatia de a
                  efectua copii de siguranta / backup a datelor pe medii de
                  stocare proprii.
                </p>
                <p>Art. 20.3.</p>
                <p>
                  Copiile de siguranta (Backup) ce pot fi folosite pentru
                  recuperarea datelor sunt generate periodic, peste noapte, prin
                  sistemul cpbackup (cPanel) si sistemul dedicat de back-up si
                  pastrate pe medii de stocare dedicate in mai multe versiuni
                  (puncte de restore). Clientii au acces prin intermediul Cpanel
                  la copiile create in acest sens pentru a-si restaura sau
                  descarca fisierele de back-up.
                </p>
                <p>
                  La cerere, incercam sa oferim clientilor arhive ale pachetului
                  de gazduire pastrate pe harddisk-ul de backup, totusi nu
                  oferim nicio garantie legata de existenta datelor sau de
                  integritatea datelor din backup. Clientii au obligatia de a
                  salva periodic copii de siguranta (backup) a datelor stocate
                  in pachetele de gazduire pe medii de proprii, folosind
                  uneltele disponibile in pachetele de gazduire.
                </p>
                <p>
                  Clientii pot solicita restore manual din partea echipei
                  tehnice SiteBunker in limita a 3 restore-uri pe luna.
                  Restore-urile suplimentare efectuate de catre echipa tehnica
                  vor fi taxate cu 20 euro + tva pe un restore.
                </p>
                <p>
                  Dupa stergerea contului de hosting de pe server clientii pot
                  solicita cautarea unui eventual back-up pe solutia de disaster
                  recovery. Aceasta se va furniza daca este identificata contra
                  unei taxe de 20 euro + tva.
                </p>
                <p>Art. 20.4.</p>
                <p>
                  Limite excludere backup pentru numar fisiere (inodes) si
                  scriere in cont:
                </p>
                <p>Nelimitat</p>
                <p>21.RESPONSABILITATILE CLIENTULUI</p>
                <p>
                  Art. 21.1. Clientul are obligatia de a actualiza informatiile
                  de contact si minim o adresa email functionala in panoul de
                  control, panoul de client SiteBunker si datele de facturare,
                  ori de cate ori este necesar. Nu putem fi trasi la raspundere
                  pentru confuzii intervenite in comunicare ca un rezultat
                  direct al neefectuarii de catre client a actualizarilor
                  necesare.
                </p>
                <p>Art. 21.2.</p>
                <p>
                  Clientul este responsabil de mentinerea in conditii de
                  siguranta a numelui de utilizator, parolelor si a altor
                  informatii sensibile cum sunt: accesul la pachetul de
                  gazduire, panoul de client, softurile folosite la site, etc….
                  Daca exista orice dubiu in acest sens clientul trebuie sa
                  modifice sau sa solicite modificarea datelor de autentificare
                  contactand echipa de asistenta in cel mai scurt timp posibil.
                </p>
                <p>
                  Art. 21.3. Clientul este in totalitate responsabil pentru
                  securizarea continutului gazduit pe serverele noastre, iar in
                  cazul in care continutul a fost compromis (infectat cu virusi,
                  shell-uri, phising, etc) este in totalitate responsabil cu
                  curatirea si securizarea continutului propriu. SiteBunker NU
                  are nici o responsabilitate in securizarea sau curatirea
                  continutului clientului.
                </p>
                <p>
                  Art. 21.4. Istoricul de acces (Access Logs) este procesat
                  zilnic de catre server si sters automat. In cazul in care se
                  doreste arhivarea istoricului de access clientul poate sa
                  salveze istoricul de acces (access log) folosind optiunea
                  disponibila in panoul de control cPanel. Istoricul de acces
                  cuprinde IP-urile care au accesat situl prin interfata http
                  si/sau ftp.
                </p>
                <p>Art. 21.5.</p>
                <p>
                  Clientul are obligativitatea de a mentine actualizate la
                  ultima (cea mai noua) versiune softurile/scripturile
                  comerciale sau gratuite folosite pe pe conturile de gazduire .
                  De asemenea clientul are obligativitatea sa faca actualizarea
                  scripturilor folosite in termen de 24 ore de la sesizarea
                  SiteBunker. In caz contrar SiteBunker isi rezerva dreptul sa
                  stearga sau sa blocheze accesul la continutul contului de
                  gazduire fara o notificare prealabila.
                </p>
                <p>
                  Art. 21.6. Clientul garanteaza ca toate materialele de orice
                  fel detinute in contul de client sunt in propritatea sa si ca
                  detine dreptul legal de folosire sau publicare, si ca orice
                  responsabilitate privind continutul contului ii revine in
                  totalitate, SiteBunker neavand nici o responsabilitate in
                  acest sens.
                </p>
                <p>22.COMUNICAREA CU CLIENTII SiteBunker</p>
                <p>
                  Art. 22.1. Comunicarea cu clientii se desfasoara pe mail, fax,
                  telefon voce sau SMS sau alte canale de comunicare utilizand
                  datele communicate de client la efectuarea comenzii sau
                  ulterior. Programul telefonic este de Luni pana Vineri in
                  intervalul 9:00 – 18:00, exceptand zilele de sarbatoare
                  legala.
                </p>
                <p>
                  Art. 22.2. Clientul este de acord sa primeasca orice
                  informatie privind serviciile,emiterea de proforme, notificare
                  de neplata, suspendare a serviciilor sau alte comunicari
                  legate de activitatea SiteBunker pe oricare din canalele de
                  comunicare de mai sus.
                </p>
                <p>
                  Art. 22.3. Comunicarea cu clienti se rezuma doar la serviciile
                  oferite de SiteBunker si nu va avea scopuri publicitare, decat
                  in situatia in care clientul isi exprima acordul in acest
                  sens.
                </p>
                <p>
                  Art. 22.4. Vom lua in considerare cererile de furnizare de
                  date, resetare de parola, modificari de date sau notificari
                  privind renuntarea la servicii doar daca sunt trimise de pe
                  adresele de mail existente in contul de client, pentru a
                  asigura verificarea identitatii clientului care face
                  solicitarea.
                </p>
                <p>
                  Art. 22.5. In cazul in care adresele de mai existente in
                  contul de client, nu mai sunt la valide la data solicitarii
                  furnizarii informatiilor de catre client, sau solicitarea de
                  informatii vine de pe o alta adresa de mail decat cea
                  existenta in contul de client, SiteBunker va solicita de la
                  client o copie dupa Cartea de Identitate si/sau Certificatul
                  de Inregistrare a societatii dupa caz, pentru a verifica
                  identitatea solicitantului. De asemena vom cere o adresa
                  semnata sau/si stampilata din partea persoanei fizice sau
                  firmei pentru a modifica adresele de mail din sistem.
                </p>
                <p>
                  Art. 22.6. Clientul este de acord si intelege ca in situatia
                  de mai sus, la solicitarea SiteBunker este obligat sa puna la
                  dispozitia acestuia documentele solicitate, in caz contrar,
                  cererea acestuia neputand fi onorata de SiteBunker.
                </p>
                <p>Art. 22.7.</p>
                <p>
                  Documentele solicitate de SiteBunker, vor fi emise in copie
                  semnata si/sau stampilata dupa caz de catre client, prin care
                  se atesta conformitatea documentului cu cel originial,
                  facandu-se in acest sens mentiunea – conform cu originalul -.
                </p>
                <p>
                  Art. 22.8. Clientul isi asuma intraga responsabilitate fata de
                  documentele solicitate si furnizate catre SiteBunker, aceasta
                  din urma avand obligatia doar de a verifica corectiudinea
                  informatiilor de pe documentele furnizate de catre client, nu
                  si valabilitatea sau legalitatea acestora, clientul
                  asumandu-si prin furnizarea lor valabilitatea si legalitatea
                  acestora si conformitatea cu originalul al acestora.
                </p>
                <p>23.RASPUNDERE LIMITATA</p>
                <p>
                  Art. 23.1. Nu putem fi considerati responsabili pentru daune
                  cauzate de indisponibilitatea temporara a serverelor noastre,
                  oricare ar fi motivul care cauzeaza acest lucru. Aceasta
                  prevedere include si daunele rezultate din deteriorarea sau
                  pierderea datelor. Clientul este de acord sa garanteze si sa
                  ne considere nevinovati in legatura cu orice pretentii si/sau
                  daune, fara a se limita la acestea, cauzate tertilor rezultate
                  ca si consecinta a utilizarii serviciilor.
                </p>
                <p>Art. 23.2.</p>
                <p>
                  SiteBunker ofera suport tehnic tuturor clientilor pentru a
                  asigura buna functionare a serviciilor oferite.
                </p>
                <p>
                  In cazul unor cereri excesive de suport tehnic in scopuri
                  personale ale clientului (restore, migrari, etc), SiteBunker
                  isi rezerva dreptul de a pune o taxa fie pe ora, fie pe
                  totalul interventiei. Migrarile gratuite se ofera numai
                  clinetilor care dispun de acces la contul de cPanel al
                  fostului provider de hosting. Fara acest acces si fara functia
                  “Backup Wizzard” activata la fostul provider de hosting, se va
                  percepe o taxa in functie de complexitatea migrarii.
                </p>
                <p>
                  Art. 23.3. SiteBunker nu este responsabila cu furnizarea catre
                  clienti al istoricul de acces al siturilor (access log),
                  acestea putand fi arhivate si descarcate de catre client din
                  panoul de control.
                </p>
                <p>Art. 23.4.</p>
                <p>
                  SiteBunker nu ofera suport tehnic privind utilizarea pentru
                  scripturile gratuite oferite clientilor.
                </p>
                <p>
                  SiteBunker isi rezerva dreptul sa renunte la a oferi in orice
                  moment scripturile oferite gratuit, fara o notificare
                  prealabila.
                </p>
                <p>
                  Scripturile gratuite oferite includ, dar nu se limiteaza la
                  RVSitebuilder, Pachetul Softaculous, etc
                </p>
                <p>
                  Serviciul de suport se ofera doar pentru utilizarea
                  serviciilor de hosting.
                </p>
                <p>
                  Art. 23.5. SiteBunker nu este resposabila si nu poate fi trasa
                  la raspundere pentru furnizarea datelor de identificare de
                  contact ale clientilor in cazurile prevazute la capitolul
                  “Inregistrarea domeniilor” din prezentul contract, clientul
                  intelegand ca fara afisarea acestor informatii nu se poate
                  realiza inregistrarea domeniilor internationale.
                </p>
                <p>
                  Art. 23.6. SiteBunker nu este responsabila si nu poate fi
                  trasa la raspundere, in cazul in care site-ul utilizatorului a
                  fost blocat si raportat ca si spam, accesul a fost blocat daca
                  au fost identificate fisiere infectate (malware) care pot
                  dauna tertilor, site-ul este exploatat pentru a putea fi
                  folosit pentru activitati cu aparenta ilegala, sau accesul pe
                  site este blocat ca urmare a defectiunii serverului sau
                  existentei unor fisiere corupte ori defecte ce cauzeaza
                  indisonibilitatea site-ului sau serviciului. In toate aceste
                  cazuri, SiteBunker raspunde in limitele, termenii si
                  conditiile prevazute la capitolul “Garantii si returnarea
                  banilor” din prezentul contract.
                </p>
                <p>
                  Art. 23.7. Clientul intelege si este de acord ca SiteBunker
                  raspunde doar in limitele si conditiile asumate la capitolul
                  “Garantii si returnarea banilor” din prezentul contract, orice
                  alta raspundere de orice natura, neputand fi angajata sau
                  pretinsa de la SiteBunker, raspunderea si garantia oferita
                  fiind limitativ prevazuta la la capitolul “Garantii si
                  returnarea banilor” din contract.
                </p>
                <p>
                  Art. 23.8. Sunteti de acord ca in cazul in care suntem
                  amenintati cu proces de catre o terta parte, putem cere
                  asigurari scrise din partea dumneavoastra, asigurari in care
                  sa ne promiteti protectie; in caz ca nu furnizati aceste
                  asigurari, aceasta poate fi considerata incalcare a
                  contractului dintre parti si poate duce la suspendarea
                  serviciului de hosting.
                </p>
                <p>
                  Art. 23.9. Clientul intelege si are obligatia de a se informa,
                  si de a accepta regulile de inregistrare, termenii si
                  conditiile impuse de partenerii SiteBunker, cu privire la
                  inregistrarea domeniilor atat cele romanesti, cat si cele
                  internationale, SiteBunker fiind doar intermediarul dintre
                  client si partner, neputand fi facut responsabil de regulile,
                  termenii si conditiile impusi de partener clientului, sau de
                  orice restrictii, solicitari, ori STRICACIUNI, PIERDERI DE
                  DATE, DETALII DE CLIENT SAU DETALII DE VANZATOR, VENIT SAU
                  ALTE PAGUBE PRODUSE AFACERILOR DATORATE INTARZIERILOR,
                  TRANSMITEREA GRESITA SAU NETRANSMITEREA INFORMATIILOR,
                  DATORATE RESTRICTIEI SAU INTRERUPERII ACCESULUI, BUG-URILOR
                  SAU ALTOR ERORI, FOLOSIRII NEAUTORIZATE DATORATE IMPARTIRII
                  ACCESULUI LA SERVICIU,SAU ALTOR INTERACTIUNI CU SERVICIUL
                  CONTRACTAT DE CLIENT DE LA SITEBUNKERSAU PRIN INTERMEDIUL
                  SITEBUNKER DE LA UNUL DIN PARTENERII ACESTUIA.
                </p>
                <p>24.DISPOZITII FINALE</p>
                <p>Art. 24.1.</p>
                <p>
                  Daca, pentru orice motiv, nu sunteti satisfacuti de serviciile
                  pe care vi le oferim va rugam sa ne contactati si sa ne
                  spuneti care este problema pentru a o rezolva. In cazul in
                  care nu va putem rezolva problema si doriti sa intrerupeti
                  contractul ne puteti informa de acest lucru si veti primi
                  inapoi banii pentru serviciile de web hosting SHARED platite
                  in avans. Plata pentru inregistrarea de domenii, Servere
                  Dedicate, Licente de orice fel, Certificate SSL, ID Protect
                  sau alte servicii conexe nu se returneaza.
                </p>
                <p>
                  Art. 24.2. Vom aloca toate resursele necesare in investigari
                  asupra actelor de violare a securitatii sistemelor sau a
                  retelei, si vom coopera cu autoritatile in cazul unor
                  infractiuni. Clientii care incalca prezentele prevederi pot
                  raspunde penal sau civil.
                </p>
                <p>
                  Art. 24.3. Pentru clienti cu mai multe conturi sau reselleri,
                  ne rezervam dreptul de a suspenda toate conturile in cazul in
                  care proprietarul contului principal incalca prezentele
                  prevederi intr-o masura considerata de noi a fi foarte grava.
                </p>
                <p>
                  Art. 24.4. Suntem unicii in masura sa judece ce anume
                  reprezinta o incalcare a termenilor si conditiilor de mai sus.
                  Neconformarea cu termenii si conditiile de mai sus constituie
                  motiv pentru suspendarea sau dezactivarea contului.
                </p>
                <p>Art. 24.5.</p>
                <p>
                  Orice litigiu aparut in legatura cu utilizare acestui serviciu
                  va fi rezolvat pe cale amiabila. In cazul in care nu s-a
                  reusit stingerea conflictului orice litigiu, decurgand din sau
                  in legătură cu acest contract, inclusiv referitor la
                  incheierea, executarea ori desfiintarea lui, se va solutiona
                  prin arbitrajul Curtii de Arbitraj Comercial Galati de pe
                  langă Camera de Comert si Industrie a Galati, in conformitate
                  cu Regulile de procedure arbitrală ale acestei Curti.
                  Hotărarea arbitrală este definitivă si obligatorie.
                </p>
                <p>Art. 24.6.</p>
                <p>
                  Nu acceptam comenzi ce contin date personale false, incluzand
                  Nume, Prenume, Adresa, Oras, Judet, Adresa de email. Orice
                  comanda de acest tip va fi anulata fara a anunta clientul in
                  prealabil.
                </p>
                <p>Art. 24.7.</p>
                <p>
                  Nu acceptam comenzi de pachete de gazduire de tip “TEST”
                  pentru domenii gratuite, pachete de gazduire care au setat
                  IP-ul dedicat, TLD-uri precum .TK, .GA, .ML, .CF, .GQ, etc. și
                  nici pentru domenii neinregistrate, domenii de stiri precum si
                  nici pentru domeniile care au legatura cu jocuri online si
                  nici pentru domeniile care au in continutul lor cuvinte cu
                  caracter pornografic. Orice comanda de acest tip va fi anulata
                  fara a anunta clientul in prealabil. Nu acceptam comenzi de
                  pachete de gazduire de tip “TEST” care au in componenta sa
                  IP-ul dedicat.
                </p>
                <p>Art. 24.8.</p>
                <p>
                  Migrarile de la vechiul provider de gazduire web se vor
                  realiza in intervalul Luni-Vineri: 9-17 cu exceptia zilelor
                  libere legale. De asemenea, migrarile vor fi realizate daca si
                  numai daca vechiul provider de hosting va oferi acces la un
                  cont de cPanel cu functia full backup activata.
                </p>
                <p>
                  25. ACORD PRIVIND PRELUCRAREA DATELOR CLIENȚILOR, CONFORM GDPR
                </p>
                <p>
                  “Acordul privind Prelucrarea Datelor Clienților, conform GDPR”
                  poate fi consultat la adresa{" "}
                  <a href="https://sitebunker.ro/acord-prelucrare-datelor/">
                    https://sitebunker.ro/politica-gdpr/
                  </a>{" "}
                  și face parte integrantă din Termeni și condiții.
                </p>
                <p>Art. 26.</p>
                <p>
                  Este interzisa minarea de criptomonede atat pe serverele
                  fizice cat si pe serverele virtuale(VPS). Este interzis
                  lansarea atacurilor de tip Phishing, Flood, DDoS, DoS de pe
                  serverele fizice cat si cele virtuale(VPS) In cazul sesizarii
                  de catre Exim Host SRL a unor actiuni mentionate mai sus,
                  serviciile de pe care sunt lansate aceste actiuni, vor fi
                  suspendate.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}
export default Gdpr
